<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Welcome!</v-card-title>

      <v-stepper v-model="currentStep" alt-labels flat elevation="0" tile>
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1"  >
            Start
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2" step="2"  >
            Profile
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 3" step="3"  >
            Preferences
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 4" step="4">
            Device
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 5" step="5">
            Summary
          </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h3>Welcome</h3>
            <div v-if="!profile" class="align-center">
              <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mb-8"></v-progress-circular>
              <div>{{ $t('shared.loading')}}</div>
            </div>
            <p>TODO: welcome text</p>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="2">
            <h3>Your Profile</h3>
            <p>We have automatically migrated your profile details to the new platform. Please review this information to ensure it's still accurate.</p>

            <v-text-field 
                v-model="profile.name" 
                :rules="nameRules" 
                :label="$t('profile.edit.displayname')" 
                prepend-icon="fa-user"
                required
                />

            <v-menu
              v-model="showDobPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedDob"
                  :label="$t('profile.edit.birthdate')"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker 
                no-title 
                ref="dobPicker"
                v-bind:value="dob"
                v-on:input="handleDobChange"
                :max="new Date().toISOString().substr(0, 10)"
                min="1920-01-01"
                >
              </v-date-picker>
            </v-menu>

            <p class="mb-0">{{$t('profile.edit.gender')}}</p>
            <v-radio-group v-model="gender" row class="mt-0" >
              <v-radio :label="$t('shared.gender-undisclosed')" value="_"></v-radio>
              <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
              <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
              <v-radio :label="$t('shared.gender-nb')" value="O"></v-radio>
            </v-radio-group>
            
            <v-text-field 
                v-model="profile.nationality" 
                :label="$t('profile.edit.nationality')" 
                prepend-icon="fa-user"
                required
                />

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn>
              <v-btn color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="3">
            <h3>Your Preferences</h3>
            <p>Please select your platform preferences. Unfortunately, we were not able to migrate this data for you.</p>

            <p class="mt-4 mb-0">Unit system</p>
            <v-radio-group v-model="unit" row class="mt-0">
              <v-radio :label="$t('profile.edit.unit-metric')" value="METRIC"></v-radio>
              <v-radio :label="$t('profile.edit.unit-imperial')" value="IMPERIAL"></v-radio>
            </v-radio-group>

            <p class="mt-4 mb-0">Profile visibility</p>
            <v-radio-group v-model="privateProfile" row class="mt-0">
              <v-radio label="Private" :value="true"></v-radio>
              <v-radio label="Visible" :value="false"></v-radio>
            </v-radio-group>
            <p v-if="!notSet(privateProfile) && privateProfile" class="my-0" >
              {{ $t('profile.edit.private-label') }}
            </p>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <!-- <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn> -->
              <v-btn :disabled="!preferencesValid" color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="4">
            <h3>Device</h3>

            <p>You will have to re-connect your device or app to the new platform to continue syncing your activities.</p>

            <p>Please select your currently connected device.</p>
            <v-select
              v-model="selectedDevice"
              :items="devices"
              item-text="text"
              item-value="value"
              solo-inverted
              class="mx-1"
              />

            <v-alert v-if="selectedDevice" type="info">
              TODO: show device specific instructions.
            </v-alert>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn>
              <v-btn :disabled="!selectedDevice" color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="5">
            <h3>Review</h3>
            <p>TODO: describe next steps.</p>
            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Complete
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Onboarding', disabled: true },
      ],
      devices: [
        { text: 'Unknown or other', value: 'unknown' },
        { text: 'Garmin', value: 'garmin' },
        { text: 'Apple Health', value: 'apple-health' },
      ],
      selectedDevice: null,
      nameRules: [
        v => !!v || "Please enter your name",
      ],
      showDobPicker: false,
      profile: null,
      dob: null,
      gender: '_',
      privateProfile: '_',
      unit: '_',
      showOk: false,
      currentStep: 1,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      let response = await profileService.keepProfile();
      if (response.data.status == 'OK') {
        this.showOk = true;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        this.dob = this.profile.dob;
      }
    },
    
    handleDobChange(e) {
      //console.log('Date picker emit new dob', e, 'parsed', this.formattedDob);
      this.dob = e;
      this.showDobPicker = false;
    },

    notSet(value) {
      return value == null || value === '' || value == '_' || value === 0;
    },
    
    async submit() {
      if (this.$refs.form.validate()) {
        console.log('this.profile.gender',this.profile.gender)
        await profileService.put({
          name: this.profile.name,
          email: this.profile.email,
          nationality: this.profile.nationality,
          unit: this.notSet(this.unit) ? null : this.unit,
          gender: this.notSet(this.gender) ? null : this.gender,
          dob: this.notSet(this.dob) ? null : this.dob,
          private: this.notSet(this.privateProfile) ? null : this.privateProfile,
          wmm_migration: true,
        });
        this.profileSavedSnackbar = true;
        // reload
        await this.getProfile();
      }
    },
  },
  computed: {
    formattedDob: {
      get() {
        console.log('/// dob ', this.dob);
        return !this.dob ? null : DateTime.fromISO(this.dob).toLocaleString(DateTime.DATE_SHORT);
      },
      set(value) {
        //this.profile.dob = value;
        //console.log('Date picker emit value', value, 'parsed to dob:', this.profile.dob);
      }
    },
    preferencesValid() {
      return !this.notSet(this.privateProfile) && !this.notSet(this.unit);
    },
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

